import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/homeView.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product/productView.vue')
  },
  {
    path: '/productDetails',
    name: 'productDetails',
    component: () => import('../views/product/productDetails.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/service/serviceView.vue')
  },
  {
    path: '/service2',
    name: 'service2',
    component: () => import('../views/service/serviceViewTwo.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case/caseView.vue')
  },
  {
    path: '/caseDetails',
    name: 'caseDetails',
    component: () => import('../views/case/caseDetails.vue')
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import('../views/partner/partnerView.vue')
  },
  {
    path: '/newsActivity',
    name: 'newsActivity',
    component: () => import('../views/newsActivity/newsActivityView.vue')
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: () => import('../views/newsActivity/newsDetails.vue')
  },
  {
    path: '/aboutJoyGovAi',
    name: 'aboutJoyGovAi',
    component: () => import('../views/aboutJoyGovAi/aboutJoyGovAi.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/loginView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
