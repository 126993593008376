<template>
    <div class="container flexSB">
        <div class="logoBox" @click="$router.push('/')"></div>
        <div class="navBox flex">
            <div class="navItem" :class="{ activeNav: this.$route.name == 'home' ? 'activeNav' : '' }"
                @click="clickNav('home')"><span class="spanL">○</span>首页<span class="spanR">○</span></div>
            <div class="navItem" v-popover:servicePopover
                :class="{ activeNav: this.$route.name == 'service' ? 'activeNav' : '' }">
                <span class="spanL">○</span>产品与服务<span class="spanR">○</span>
            </div>
            <div class="navItem" :class="{ activeNav: this.$route.name == 'product' ? 'activeNav' : '' }"
                @click="clickNav('product')"><span class="spanL">○</span>解决方案<span class="spanR">○</span></div>
            <div class="navItem" :class="{ activeNav: this.$route.name == 'case' ? 'activeNav' : '' }"
                @click="clickNav('case')"><span class="spanL">○</span>成功案例<span class="spanR">○</span></div>
            <div class="navItem" :class="{ activeNav: this.$route.name == 'newsActivity' ? 'activeNav' : '' }"
                @click="clickNav('newsActivity')"><span class="spanL">○</span>新闻与活动<span class="spanR">○</span></div>
            <div class="navItem" :class="{ activeNav: this.$route.name == 'aboutJoyGovAi' ? 'activeNav' : '' }"
                @click="clickNav('aboutJoyGovAi')"><span class="spanL">○</span>关于乐政<span class="spanR">○</span></div>
            <el-popover ref="servicePopover" placement="bottom" trigger="hover">
                <div class="popoverItem cs" v-for="item in serviceMenu" :key="item.id" @click="toService(item.id)">{{
                    item.name }}</div>
            </el-popover>
        </div>
        <div class="telBox">
            <div class="telItem">
                <span>Tel：</span>028-60106484
            </div>
            <div class="telLien"></div>
            <div class="btnBox" @click="clickOnTrial">
                申请试用
            </div>
            <div class="telLien"></div>
            <div class="btnBox" v-if="loginFlag == false" @click="goLogin">
                登录
            </div>
            <div class="userHeader cs" @click="loginOut" v-else>
                登出
            </div>
        </div>
    </div>
</template>

<script>
import { getMenuList, getServiceMenu } from '../assets/js/request/api';
export default {
    data() {
        return {
            menuList: [],
            serviceMenu: [],
            userInfo: {},
            loginFlag: false,
        }
    },
    created() {
        this.getList();
        this.getServiceMenuList();
        this.setUserInfo();
    },
    methods: {
        clickNav(item) {
            if (this.$route.name == item) return
            this.$router.push({ name: item })
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },
        clickLogo() {
            this.$router.push({ name: 'home' })
        },
        loginOut() {
            this.$confirm('您确定要退出登录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                localStorage.clear()
                this.loginFlag = false
                this.$message({
                    type: 'success',
                    message: '退出成功!'
                });
            }).catch(() => {
                
            });
        },
        clickOnTrial() {
            this.$emit('clickOnTrial')
        },
        getList() {
            getMenuList().then(res => {
                // console.log(res);
                if (res.code == 200) {
                    this.menuList = res.data
                }
            })
        },
        goLogin() {
            this.$router.push({ name: 'login' })
        },
        getServiceMenuList() {
            getServiceMenu().then(res => {
                // console.log(res);
                if (res.code == 200) {
                    this.serviceMenu = res.data
                }
            })
        },
        toService(id) {
            let routerId = this.$route.query.id
            if (routerId == id) return
            this.$router.push({ name: 'service', query: { id } })
        },
        setUserInfo() {
            let user = localStorage.getItem('userInfo')
            if (user) {
                this.userInfo = JSON.parse(user)
                this.loginFlag = true
            } else {
                this.loginFlag = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.popoverItem {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.popoverItem:hover {
    color: #3167C3;
}

.container {
    height: 80px;
    align-items: center;
    background: #fff;
    padding: 0 44px 0 80px;
    box-shadow: 0px 4px 51px 11px rgba(153, 153, 153, 0.11);
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 111;

    .logoBox {
        width: 92px;
        height: 34px;
        cursor: pointer;
        background-image: url('../assets/img/home/logo.png');
        background-size: 100% 100%;
    }

    .navBox {
        font-size: 18px;
        font-weight: 500;

        .navItem {
            margin-left: 38px;
            cursor: pointer;
            display: flex;
            position: relative;

            span {
                display: none;
                position: absolute;
            }

            .spanL {
                left: -12px;
            }

            .spanR {
                right: -12px;
            }
        }

        .navItem:first-child {
            margin-left: 0;
        }

        .activeNav {
            color: #3167C3;

            span {
                display: block;
            }
        }
    }

    .telBox {
        font-size: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .telItem {
            span {
                font-size: 16px;
                font-weight: 400;
            }
        }

        .btnBox {
            color: #002CB4;
            cursor: pointer;
        }

        .telLien {
            width: 1px;
            height: 30px;
            background-color: #ccc;
            margin: 0 10px;
        }

        .userHeader {
            display: flex;
            align-items: center;

            color: #999;
        }

    }

}
</style>
