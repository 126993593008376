import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
//  让请求在浏览器中允许跨域携带cookie
// axios.defaults.withCredentials = true;

// const baseURL = 'http://192.168.0.46:18088/';
const baseURL = 'https://www.joygov.com/webAdmin/';//正式环境
const service = axios.create({
    // 基础的请求地址
    baseURL: baseURL,
    // 设置超时时间
    timeout: 60000
});
// 请求拦截器，例如请求的时候在头部加上请求的token
service.interceptors.request.use(config => {
    return config;  //  有且必须有一个config对象被返回
}, error => {
    //   对请求错误做些什么
    console.log(error);
    return Promise.reject();
});
// 响应拦截器,例如判断服务器返回的状态，400，500啥的，其实超时可以写到这里面来，我分开写了一个比较直观
service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            // console.log("=======>" + response.data.code);
            // if (response.data.code != 0) Notify({ type: "primary", message: response.data.msg });
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况,这些自定义（需要与后台商量返回）
    error => {
        if (
            400 <= error.response.status < 500
        ) {
            // Notify({ type: "primary", message: "用户信息过期，请重新登陆" });
            // 清除token
            // localStorage.removeItem("token");
            // 跳转登录
            setTimeout(() => {
                //   window.location.href = "/login";
            }, 1000);
        } else {
            if (error.response.status >= 500) {
                Message({
                    message: '服务器开小差了，请稍后再试！',
                    type: 'warning'
                });
            } else {
                Message({
                    message: '服务器开小差了，请稍后再试！',
                    type: 'warning'
                });
                return Promise.reject(error)
            }
        }
    }
);
export const apiBaseUrl = baseURL;
export default service;
