<template>
    <div class="container">
        <div class="bgBox">
            <div class="title1">Leader in the field of government public services</div>
            <div class="title2">政府公共服务领域领跑者</div>
            <!-- <div class="btnBox">欢迎咨询</div> -->
        </div>
        <div class="containerBox">
            <div class="contentBox flexSB">
                <div class="telAddrBox">
                    <div class="telName">24小时服务热线：</div>
                    <div class="tel">{{ info.phone.value }}</div>
                    <div class="addrName">公司地址：</div>
                    <div class="addr">中国(四川)自由贸易试验区成都高新区<br />益州大道中段1800号G区5栋2302号</div>
                </div>
                <div class="line"></div>
                <div class="homeBox">
                    <div class="title">产品与服务</div>
                    <div class="homeItem">政务服务外包</div>
                    <div class="homeItem">应急管理平台</div>
                    <div class="homeItem">AI数智员工</div>
                    <div class="homeItem">招投标服务平台</div>
                </div>
                <div class="line"></div>
                <div class="productBox">
                    <div class="title">解决方案</div>
                    <div class="homeItem">智慧政务</div>
                    <div class="homeItem">智慧应急</div>
                    <div class="homeItem">智慧人社</div>
                    <div class="homeItem">智慧园区</div>
                </div>
                <div class="line"></div>
                <div class="productBox">
                    <div class="title">成功案例</div>
                    <div class="homeItem">政务服务外包</div>
                    <div class="homeItem">AI数智员工</div>
                    <div class="homeItem">智慧应急</div>
                    <div class="homeItem">智慧园区</div>
                </div>
                <div class="line"></div>
                <div class="QRcodeBox">
                    <div class="logoBox"></div>
                    <div class="ewmBox">
                        <div class="erweima" :style="{ backgroundImage: `url(${info.officeAccount.value})` }"></div>
                        <div class="ewmTips">扫码关注公众号</div>
                    </div>
                    <div class="btnBox flex">
                        <div class="cs btnBoxLine">法律声明</div>
                        <div class="cs btnBoxLine">隐私保护</div>
                        <div class="cs">服务协议</div>
                    </div>
                </div>
            </div>

        </div>
        <div class="line"></div>
        <div class="containerBox">
            <div class="copyrightBox">Copyright @2018-2024四川乐政科技有限公司 <a target="_blank"
                    style="text-decoration: none;color: #999" rel="noopener"
                    href="https://beian.miit.gov.cn/#/Integrated/recordQuery">蜀ICP备20015756号</a></div>
        </div>
    </div>
</template>

<script>
import { getAboutInfo } from '../assets/js/request/api';
export default {
    data() {
        return {
            info: {},
        }
    },
    created() {
        this.getInfo();
    },
    methods: {
        getInfo() {
            getAboutInfo().then(res => {
                this.info = Object.assign({}, ...res.data.companyBaseInfos);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    .bgBox {
        height: 300px;
        width: 100%;
        background-image: url('../assets/img/home/footerBg.png');
        background-size: 100% 100%;
        color: #fff;
        padding-top: 95px;

        .title1 {
            font-size: 24px;
            font-weight: 900;
            line-height: 24px;
            text-align: center;
        }

        .title2 {
            font-size: 30px;
            font-weight: bold;
            line-height: 30px;
            text-align: center;
            margin: 16px 0 29px 0;
        }

        .btnBox {
            width: 180px;
            height: 54px;
            border-radius: 27px;
            line-height: 54px;
            cursor: pointer;
            text-align: center;
            margin: 0 auto;
            border: 1px solid #fff;
        }
    }

    .containerBox {
        padding: 0 200px;
    }

    .contentBox {
        height: 366px;

        .telAddrBox,
        .homeBox,
        .productBox,
        .QRcodeBox {
            padding-top: 73px;
        }

        .telName {
            font-size: 16px;
            font-weight: 400;
        }

        .tel {
            font-size: 36px;
            font-weight: bold;
            color: #000;
            margin-bottom: 125px;
        }

        .addrName {
            margin-bottom: 10px;
        }

        .addrName,
        .addr {
            font-size: 16px;
            font-weight: 400;
        }

        .title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 30px;
        }

        .homeItem {
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 20px;
            cursor: pointer;
        }

        .line {
            width: 1px;
            height: 366px;
            background-color: #ccc;
        }

        .QRcodeBox {
            .logoBox {
                width: 72px;
                height: 26px;
                margin-left: 68px;
                background-image: url('../assets/img/home/logo.png');
                background-size: 100% 100%;
            }

            .ewmBox {
                font-size: 16px;
                font-weight: 400;
                margin-top: 9px;
                margin-left: 46px;

                .erweima {
                    width: 118px;
                    height: 118px;
                    background-color: #EEF0F5;
                    // background-image: url('../assets/img/ewmIcon.png');
                    background-size: 100% 100%;
                }

                .ewmTips {
                    margin-left: 2px;
                }
            }

            .btnBox {
                font-size: 16px;
                font-weight: 400;
                margin-top: 61px;

                div {
                    position: relative;
                    padding-right: 10px;
                }

                .btnBoxLine::after {
                    position: absolute;
                    top: 3px;
                    height: 17px;
                    right: 4px;
                    content: '';
                    width: 0;
                    border-right: solid #585858 1px;
                }
            }
        }
    }

    .line {
        height: 1px;
        background-color: #ccc;
    }

    .copyrightBox {
        height: 96px;
        font-size: 16px;
        line-height: 16px;
        color: #999;
        font-weight: 400;
        padding-top: 58px;
    }
}
</style>
