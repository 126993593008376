import request from './axios';

// 获取一级菜单列表
export const getMenuList = (query) => {
    return request({
        url: 'base/info/menu',
        method: 'get',
        params: query
    })
}
// 获取产品与服务二级菜单列表
export const getServiceMenu = (query) => {
    return request({
        url: 'product-service/info/home-menu',
        method: 'get',
        params: query
    })
}
// 获取banner，参数menuId，（0首页，1产品与服务(该菜单无轮播banner)，2解决方案，3成功案例，4新闻与活动，5关于乐政）
export const getBanner = (query) => {
    return request({
        url: 'banner/img/home',
        method: 'get',
        params: query
    })
}
// 获取首页新闻与活动
export const getHomeNews = (query) => {
    return request({
        url: 'publish/publish/home',
        method: 'get',
        params: query
    })
}
// 获取首页合作企业列表
export const getHomeCompletely = (query) => {
    return request({
        url: 'system/partnership/home',
        method: 'get',
        params: query
    })
}
// 获取新闻、活动、展会列表
export const getNewsList = (query) => {
    return request({
        url: 'publish/publish/portal',
        method: 'get',
        params: query
    })
}
// 首页查询成功案例列表
export const getHomeDoList = (query) => {
    return request({
        url: 'successCases/home',
        method: 'get',
        params: query
    })
}
// 获取新闻、展会、活动详情
export const getNewsDetails = (query) => {
    return request({
        url: '/publish/publish/' + query.id,
        method: 'get',
        // params: query
    })
}
// 成功案例项目类别
export const getCaseCategory = (query) => {
    return request({
        url: 'successCases/project',
        method: 'get',
        params: query
    })
}
// 成功案例项目轮播列表
export const getCaseBanner = (query) => {
    return request({
        url: 'successCases/carousel',
        method: 'get',
        params: query
    })
}
// 成功案例项目列表
export const getCaseList = (query) => {
    return request({
        url: 'successCases/content/overview',
        method: 'get',
        params: query
    })
}
// 成功案例详情
export const getCaseDetails = (query) => {
    return request({
        url: 'successCases/content/'+query.id,
        method: 'get',
        // params: query
    })
}
// 获取解决方案总览信息列表
export const getProductList = (query) => {
    return request({
        url: 'solution/info/overview',
        method: 'get',
        params: query
    })
}
// 获取解决方案详情列表
export const getProductDetails = (query) => {
    return request({
        url: 'solution/info/content/portal',
        method: 'get',
        params: query
    })
}
// 获取解决方案行业痛点
export const getProductPain = (query) => {
    return request({
        url: 'solution/info/pain/portal',
        method: 'get',
        params: query
    })
}
// 获取解决方案基本信息
export const getProductInfo = (query) => {
    return request({
        url: 'solution/info/portal',
        method: 'get',
        params: query
    })
}
// 获取产品与服务--基本信息
export const getServiceInfo = (query) => {
    return request({
        url: 'product-service/info/portal',
        method: 'get',
        params: query
    })
}
// 获取产品与服务--优势信息
export const getServiceAdvantage = (query) => {
    return request({
        url: 'product-service/info/advantage/portal',
        method: 'get',
        params: query
    })
}
// 获取产品与服务--内容信息
export const getServiceContent = (query) => {
    return request({
        url: 'product-service/info/content/portal',
        method: 'get',
        params: query
    })
}
// 获取产品与服务--应用场景
export const getServiceScenarios = (query) => {
    return request({
        url: 'product-service/info/scenarios/portal',
        method: 'get',
        params: query
    })
}
// 获取产品与服务--相关文档
export const getServiceDocuments = (query) => {
    return request({
        url: 'product-service/info/documents/portal',
        method: 'get',
        params: query
    })
}
// 获取产品与服务--客户信息
export const getServiceClients = (query) => {
    return request({
        url: 'product-service/info/clients/portal',
        method: 'get',
        params: query
    })
}
// 获取关于乐政--发展历程
export const getHistoryJoyGov = (query) => {
    return request({
        url: 'development/history/list',
        method: 'get',
        params: query
    })
}
// 获取手机验证码
export const getPhoneCode = (query) => {
    return request({
        url: 'verify/code',
        method: 'post',
        data: query
    })
}
// 用户注册
export const postRegister = (query) => {
    return request({
        url: 'register',
        method: 'post',
        data: query
    })
}
// 用户登录
export const postLogin = (query) => {
    return request({
        url: 'portal/login',
        method: 'post',
        data: query
    })
}
// 查询用户申请状态
export const postProduct = (query) => {
    return request({
        url: 'apply/use/product',
        method: 'post',
        data: query,
		headers: {
			Authorization: 'Bearer '+query.token
		}
    })
}
// 申请试用
export const postApple = (query) => {
    return request({
        url: 'apply/use',
        method: 'post',
        data: query,
		headers: {
			Authorization: 'Bearer '+query.token
		}
    })
}
// 立即咨询
export const postConsultation = (query) => {
    return request({
        url: 'apply/consultation',
        method: 'post',
        data: query,
		headers: {
			Authorization: 'Bearer '+query.token
		}
    })
}
// 找回密码
export const postRetrievePassword = (query) => {
    return request({
        url: 'retrieve/password',
        method: 'post',
        data: query,
		headers: {
			Authorization: 'Bearer '+query.token
		}
    })
}
// 获取公司介绍等信息
export const getAboutInfo = (query) => {
    return request({
        url: 'base/info/industry-info',
        method: 'get',
        params: query
    })
}