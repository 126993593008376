<template>
  <div id="app">
    <div v-if="this.$route.name != 'login'">
      <headerView @clickOnTrial="clickOnTrial"></headerView>
      <router-view @clickOnTrial="clickOnTrial" @clickZX="clickZX" />
      <footerView></footerView>
      <div class="goTopBtn">
        <div class="contactBox" v-popover:popover>
          <div class="iconBox"></div>
          <div class="nameBox">联系方式</div>
        </div>
        <div class="probationBox" @click="clickOnTrial">
          <div class="iconBox"></div>
          <div class="nameBox">申请试用</div>
        </div>
        <div class="goTopBox" @click="goTop">
        </div>
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
    <el-popover ref="popover" placement="left" trigger="click">
      <div class="contactContent">
        <div class="titleBox">商务微信</div>
        <div class="chatBox" :style="{ backgroundImage: `url(${info.wechat.value})` }"></div>
        <div class="titleBox">联系电话</div>
        <div class="telBox">{{ info.phone.value }}</div>
      </div>
    </el-popover>
    <!-- 申请试用 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="dialogFormVisible">
      <div class="dialogTitleBox" slot="title">
        乐政科技产品试用申请<span>我们将严格保护您的申请隐私信息</span>
      </div>
      <div class="dialogTipsBox">
        为了我们能够更好地服务您，请填写以下信息，我们将在24小时内安排专家联系您，您也可以拨打免费服务热线：<span>028-60106484</span>进行咨询
      </div>
      <el-form :model="form" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item label="姓名：" prop="name" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="phoneNumber" :label-width="formLabelWidth">
          <el-input v-model="form.phoneNumber" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="单位名称：" prop="corporateName" :label-width="formLabelWidth">
          <el-input v-model="form.corporateName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="申请试用产品：" prop="productIds" :label-width="formLabelWidth">
          <el-checkbox-group v-model="form.productIds" size="small">
            <el-checkbox v-for="item in list" :key="item.id" :label="item.id" :disabled="item.applyStatus == 1">{{
              item.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="loading">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 立即咨询 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="dialogFormVisible2">
      <div class="dialogTitleBox" slot="title">
        立即咨询<span>我们将严格保护您的申请隐私信息</span>
      </div>
      <div class="dialogTipsBox">
        为了我们能够更好地服务您，请填写以下信息，我们将在24小时内安排专家联系您，您也可以拨打免费服务热线：<span>028-60106484</span>进行咨询
      </div>
      <el-form :model="form2" :rules="rules2" ref="ruleForm2" class="demo-ruleForm">
        <el-form-item label="姓名：" prop="name" :label-width="formLabelWidth">
          <el-input v-model="form2.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="phoneNumber" :label-width="formLabelWidth">
          <el-input v-model="form2.phoneNumber" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="单位名称：" prop="corporateName" :label-width="formLabelWidth">
          <el-input v-model="form2.corporateName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="submitForm2('ruleForm2')" :loading="loading2">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import headerView from './components/headerView.vue'
import footerView from './components/footerView.vue'
import { postProduct, postApple, postConsultation, getAboutInfo } from './assets/js/request/api'
export default {
  components: {
    headerView,
    footerView
  },
  data() {
    return {
      visible: false,
      dialogFormVisible: false,
      dialogFormVisible2: false,
      loading: false,
      loading2: false,
      form: {
        name: '',
        userId: '',
        token: '',
        phoneNumber: '',
        corporateName: '',
        productIds: [],
      },
      form2: {
        name: '',
        userId: '',
        token: '',
        phoneNumber: '',
        corporateName: '',
        menuId: '',
        consultId: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        phoneNumber: [
          { required: true, message: '请输入您的手机号码', trigger: 'blur' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[1-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/
              if (!passwordreg.test(value)) {
                callback(
                  new Error(
                    '请输入正确的11位手机号码!'
                  )
                )
              } else {
                callback()
              }
            }
          }
        ],
        corporateName: [
          { required: true, message: '请输入您的单位名称', trigger: 'blur' },
        ],
        productIds: [
          { required: true, message: '请选择您要试用的产品', trigger: 'change' },
        ],
      },
      rules2: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        phoneNumber: [
          { required: true, message: '请输入您的手机号码', trigger: 'blur' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[1-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/
              if (!passwordreg.test(value)) {
                callback(
                  new Error(
                    '请输入正确的11位手机号码!'
                  )
                )
              } else {
                callback()
              }
            }
          }
        ],
        corporateName: [
          { required: true, message: '请输入您的单位名称', trigger: 'blur' },
        ],
      },
      formLabelWidth: '130px',
      loginFlag: false,
      list: [],
      userInfo: {},
      info: {},
    }
  },
  created() {
    if (this.isPhone()) {
      window.location.href = 'https://www.joygov.com/webMobile';
    }
    let user = localStorage.getItem('userInfo')
    if (user) {
      let userInfo = JSON.parse(user)
      this.userInfo = JSON.parse(user)
      this.form.userId = userInfo.userId
      this.form.token = userInfo.token
      this.form2.userId = userInfo.userId
      this.form2.token = userInfo.token
    }
    this.getInfo();
  },
  methods: {
    goTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    clickOnTrial() {
      let user = localStorage.getItem('userInfo')
      if (user) {
        this.form.phoneNumber = JSON.parse(user).username
        this.dialogFormVisible = true;
        this.getList(JSON.parse(user).token)
      } else {
        this.$confirm('该操作需要先登录, 是否立即前往登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push('login')
        }).catch(() => {

        });
      }
      console.log(this.$route.query)
    },
    isPhone() {
      // Regular expressions to match mobile user agents
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

      // Get the user agent string from the window.navigator object
      const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

      // Test the user agent string against the regular expression
      return mobileRegex.test(userAgent);
    },
    clickZX(e, id) {
      console.log(e)
      let user = localStorage.getItem('userInfo')
      if (user) {
        this.form2.phoneNumber = JSON.parse(user).username
        this.form2.menuId = e;
        this.form2.consultId = id
        this.getList(JSON.parse(user).token)
        this.dialogFormVisible2 = true;
      } else {
        this.$confirm('该操作需要先登录, 是否立即前往登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push('login')
        }).catch(() => {

        });
      }
    },
    changeLogin() {
      this.$refs.headerViews.setUserInfo();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          postApple(this.form).then(res => {
            console.log(res);
            if (res.code == 200) {
              this.$message.success('申请成功！')
              this.loading = false;
              this.getList(this.userInfo.token)
              this.resetForm(formName)
              setTimeout(() => {
                this.dialogFormVisible = false;
              }, 500)
            } else {
              this.$message.warning(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    submitForm2(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading2 = true
          postConsultation(this.form2).then(res => {
            console.log(res);
            if (res.code == 200) {
              this.$message.success('提交成功！')
              this.loading2 = false
              this.resetForm(formName)
              setTimeout(() => {
                this.dialogFormVisible2 = false;
              }, 500)
            } else {
              this.$message.warning(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    getList(token) {
      postProduct({ token }).then(res => {
        if (res.code == 200) {
          this.list = res.data
        } else if (res.code == 401) {
          this.$message.success('登录过期，请重新登录！')
          this.loginFlag = false;
          localStorage.clear();
          this.$router.push('login')
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getInfo() {
      getAboutInfo().then(res => {
        this.info = Object.assign({}, ...res.data.companyBaseInfos);
      })
    }
  }
}
</script>

<style lang="scss">
#app {

  // position: relative;
  .goTopBtn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 11;
    position: fixed;
    right: 34px;
    bottom: 60px;
    background-color: rgba($color: #ffffff, $alpha: 0.8);
    border-radius: 8px;
    padding: 14px 9px;
    font-size: 12px;

    .iconBox {
      width: 25px;
      height: 25px;
      margin-bottom: 2px;
      background-size: 100% 100%;
    }

    .nameBox {
      color: #697898;
    }

    .contactBox {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;

      .iconBox {
        background-image: url('./assets/img/home/contact.png');
      }
    }

    .contactBox:hover {
      .iconBox {
        background-image: url('./assets/img/home/contact2.png');
      }
    }

    .probationBox {
      margin: 18px 0;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;

      .iconBox {
        width: 25px;
        height: 20px;
        background-image: url('./assets/img/home/probation.png');
      }
    }

    .probationBox:hover {
      .iconBox {
        background-image: url('./assets/img/home/probation2.png');
      }
    }

    .goTopBox {
      cursor: pointer;
      width: 24px;
      height: 40px;
      background-image: url('./assets/img/home/goTop.png');
      background-size: 100% 100%;
    }
  }

  .dialogTitleBox {
    font-size: 20px;
    font-weight: bold;
    border-left: 4px solid #002BBE;
    padding-left: 15px;

    span {
      font-size: 16px;
      font-weight: 500;
      margin-left: 15px;
    }
  }

  .dialogTipsBox {
    margin-bottom: 30px;

    span {
      color: #002BBE;
      font-weight: bold;
    }
  }
}

.contactContent {
  .titleBox {
    font-size: 12px;
    color: #333;
    margin-bottom: 5px;
  }

  .chatBox {
    width: 60px;
    height: 60px;
    // background-image: url('./assets/img/home/chatIcon.png');
    background-size: 100% 100%;
    margin-bottom: 13px;
  }

  .telBox {
    color: #000;
    font-weight: bold;
  }
}
</style>
