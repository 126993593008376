var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"containerBox"},[_c('div',{staticClass:"contentBox flexSB"},[_c('div',{staticClass:"telAddrBox"},[_c('div',{staticClass:"telName"},[_vm._v("24小时服务热线：")]),_c('div',{staticClass:"tel"},[_vm._v(_vm._s(_vm.info.phone.value))]),_c('div',{staticClass:"addrName"},[_vm._v("公司地址：")]),_vm._m(1)]),_c('div',{staticClass:"line"}),_vm._m(2),_c('div',{staticClass:"line"}),_vm._m(3),_c('div',{staticClass:"line"}),_vm._m(4),_c('div',{staticClass:"line"}),_c('div',{staticClass:"QRcodeBox"},[_c('div',{staticClass:"logoBox"}),_c('div',{staticClass:"ewmBox"},[_c('div',{staticClass:"erweima",style:({ backgroundImage: `url(${_vm.info.officeAccount.value})` })}),_c('div',{staticClass:"ewmTips"},[_vm._v("扫码关注公众号")])]),_vm._m(5)])])]),_c('div',{staticClass:"line"}),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgBox"},[_c('div',{staticClass:"title1"},[_vm._v("Leader in the field of government public services")]),_c('div',{staticClass:"title2"},[_vm._v("政府公共服务领域领跑者")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addr"},[_vm._v("中国(四川)自由贸易试验区成都高新区"),_c('br'),_vm._v("益州大道中段1800号G区5栋2302号")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homeBox"},[_c('div',{staticClass:"title"},[_vm._v("产品与服务")]),_c('div',{staticClass:"homeItem"},[_vm._v("政务服务外包")]),_c('div',{staticClass:"homeItem"},[_vm._v("应急管理平台")]),_c('div',{staticClass:"homeItem"},[_vm._v("AI数智员工")]),_c('div',{staticClass:"homeItem"},[_vm._v("招投标服务平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productBox"},[_c('div',{staticClass:"title"},[_vm._v("解决方案")]),_c('div',{staticClass:"homeItem"},[_vm._v("智慧政务")]),_c('div',{staticClass:"homeItem"},[_vm._v("智慧应急")]),_c('div',{staticClass:"homeItem"},[_vm._v("智慧人社")]),_c('div',{staticClass:"homeItem"},[_vm._v("智慧园区")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productBox"},[_c('div',{staticClass:"title"},[_vm._v("成功案例")]),_c('div',{staticClass:"homeItem"},[_vm._v("政务服务外包")]),_c('div',{staticClass:"homeItem"},[_vm._v("AI数智员工")]),_c('div',{staticClass:"homeItem"},[_vm._v("智慧应急")]),_c('div',{staticClass:"homeItem"},[_vm._v("智慧园区")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btnBox flex"},[_c('div',{staticClass:"cs btnBoxLine"},[_vm._v("法律声明")]),_c('div',{staticClass:"cs btnBoxLine"},[_vm._v("隐私保护")]),_c('div',{staticClass:"cs"},[_vm._v("服务协议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"containerBox"},[_c('div',{staticClass:"copyrightBox"},[_vm._v("Copyright @2018-2024四川乐政科技有限公司 "),_c('a',{staticStyle:{"text-decoration":"none","color":"#999"},attrs:{"target":"_blank","rel":"noopener","href":"https://beian.miit.gov.cn/#/Integrated/recordQuery"}},[_vm._v("蜀ICP备20015756号")])])])
}]

export { render, staticRenderFns }